.global-container {
  position: fixed;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ia {
  position: absolute;
  bottom: 0;
  right: 0;
  animation-name: showIconIA;
  animation-duration: 4s;
  animation-direction: normal;
}

.ia-hidden {
  background-color: rgba(200, 200, 200, 0.1);
  animation-name: hiddeIconIA;
  animation-duration: 4s;
  animation-direction: normal;
}
@keyframes fadeInOut {
  0%, 100% {
    opacity: 9;
  }
  50% {
    opacity: 0.3;
  }
}

.fading-element {
  animation: fadeInOut 2s linear infinite;
}
@keyframes showIconIA {
  from {
    bottom: 200px;
    padding: 8px;
  }

  to {
    bottom: 200px;
    right: 0;
    padding: 8px;
  }
}
@keyframes hiddeIconIA {
  from {
    bottom: 0px;
    right: 0;
    height: 100vh;
    width: 100%;
    padding: 8px;
  }

  to {
    right: 0px;
    bottom: 200px;
    padding: 8px;
    height: 100vh;
    width: auto;
  }
}
